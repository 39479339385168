
import React, {  useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { toast } from 'react-toastify';
import { onMessageListner, requestFCMToken } from './services/utils/firebaseUtils';
import io from "socket.io-client";
const socket = io('http://localhost:5203');
socket.on('connect', () => {
  console.log('Connected to server on port 5203');
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('../src/layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const EventList = React.lazy(() => import('./views/pages/event/event-list'));


function App(){

  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
// debugger
    socket.on('user_block_by_admin', (data) => {
     console.log(data,'---------------')
    });


    const fetchFCMToken = async () => {
      try {
        const token = await requestFCMToken();
        console.log("FCM", token);
        setFcmToken(token);
      } catch (err) {
        console.error("Error geting FCM Token: ", err);
      }
    }
    fetchFCMToken()
  },[]); 
 

  // console.log(onMessageListner)

  onMessageListner().then(payload=>{
    // console.log(payload,"appp")
    toast.success(payload?.notification?.body)
  }).catch(er=>{
    console.log(er,"error")
  })

  return <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            <Route path="/" name="CMS" render={props => <TheLayout {...props}/>} />
          </Switch>
        </React.Suspense>
    </HashRouter>
  
  
}

export default App;
